%button-reset {
  border: 0;
  background: none;
  cursor: pointer;
}

%list-reset {
  padding: 0;
  list-style: none;
}

%flex-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
}

%sidebar {
  position: relative;
  min-width: 276px;
  flex-shrink: 0;
  width: 19.1%;
  margin-left: 24px;
  border-radius: 16px;
}

@mixin hover {
  @media (hover: hover) {
    &:not([disabled]):hover {
      @content;
    }
  }
}

@mixin rtl {
  *[dir='rtl'] {
    @content;
  }
}

body[dir='rtl'] {
  %sidebar {
    margin: 0 24px 0 0;
  }
}
