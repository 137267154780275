@mixin input {
  display: block;
  width: 100%;
  padding: 9px 16px;
  border: 2px solid #E0E3E6;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  resize: none;

  &::placeholder {
    font-size: 16px;
    line-height: 30px;
    color: #6E757C;
  }
}

@mixin label {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
}

@mixin error {
  color: var(--errors);
  font-size: 13px;
  font-weight: 500;
}

@mixin required {
  color: var(--errors);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
