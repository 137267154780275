@import 'src/breakpoints';

.row {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  .label_text {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: var(--slate);
    margin: 0;
  }

  .input_form {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    border: 2px solid #E0E3E6;
    border-radius: 8px;
    margin: 4px 0 18px;
    padding: 8px 16px;

    &.change_pass_input {
      padding: 8px 16px 8px 44px;
    }

    &:read-only {
      color: var(--read-only);
    }
  }

  .label_required {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--errors);
    opacity: 0.8;
    margin: 0 0 0 2px;
  }

  &.input_error {
    position: relative;

    .input_form {
      border: 2px solid var(--errors);
    }
  }

  .error {
    font-size: 12px;
    color: var(--errors);
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;

    .error_icon {
      margin: 0 5px 0 0;
    }

    @media (max-width: $mobile2) {
      font-size: 12px;
      bottom: 0;
    }
  }

  .icon_password {
    position: absolute;
    bottom: 34px;
    display: flex;
    left: 14px;
    cursor: pointer;
  }
}
