.popover {
  position: absolute;
  z-index: 20;
  left: -230px;
}

.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

body[dir='rtl'] {
  .popover {
    left: unset;
    right: -230px;
  }
}
