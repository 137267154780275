@import 'src/breakpoints';

.story_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 32px 24px;
}

.story_meta {
  display: flex;
  align-items: center;
}

.story_data {
  padding-left: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: var(--second-text-color);
}

.story_tags span {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  padding: 0 6px;
  color: var(--second-text-color);
}

.story_content {
  position: relative;
  z-index: 1;
  height: calc(100vh - 300px);
  padding: 0 32px 32px;
}

.story_title {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: var(--pickled);
}

.story_image {
  width: 100%;
  height: 332px;
  margin: 24px 0;
  border-radius: 8px;
  object-fit: cover;
}

.gallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px 16px;
  margin-bottom: 24px;
}

.gal_image {
  width: 100%;
  height: 154px;
  border-radius: 8px;
  object-fit: cover;
}

.story_text {
  margin: 24px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  white-space: pre-wrap;
  color: var(--pickled);
}

.video_wrap {
  margin: 20px 0;
}

.video_wrap video {
  border-radius: 8px;
  overflow: hidden;
}

.detailed_user_wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: calc(100% - 87px);
  margin-top: 87px;
  background: var(--shadow);
}

.detailes_user_content {
  position: relative;
  width: 50%;
  min-height: calc(100vh - 205px);
  height: 100%;
  background-color: var(--white);
}

.detailed_user_info {
  position: relative;
  display: flex;
  align-items: center;
  padding: 32px;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 32px;
    width: calc(100% - 64px);
    height: 1px;
    background: var(--catskill);
  }
}

.detailed_user_avatar {
  width: 104px;
  height: 104px;
  margin-right: 24px;
  border: 2px solid var(--lightgray2);
  border-radius: 196.544px;
  object-fit: cover;
  overflow: hidden;
}

.detailed_user_name {
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  color: var(--pickled);
}

.detailed_user_role {
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: var(--second-text-color);
  text-transform: capitalize;
}

.detailed_user_location {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: var(--pickled);

  svg {
    margin-right: 10px;
  }
}

.author_info {
  display: flex;
  align-items: center;
  padding-right: 16px;
  border-right: 1px solid var(--catskill);

  .author_avatar {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  .author_name {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    color: var(--active-navlink);
    cursor: pointer;
  }
}

.country_info {
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-right: 1px solid var(--catskill);

  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    color: var(--active-navlink);
  }
}

.close_user_info {
  position: absolute;
  top: 45px;
  right: 38px;
  cursor: pointer;
}

.actions {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: auto;
}

body[dir='rtl'] {
  .actions {
    margin-left: unset;
    margin-right: auto;
  }

  .author_info {
    padding-right: unset;
    padding-left: 16px;
    border-right: none;
    border-left: 1px solid var(--catskill);
  }

  .country_info {
    padding-right: unset;
    padding-left: 16px;
    border-right: none;
    border-left: 1px solid var(--catskill);
  }

  .author_avatar {
    margin-right: unset;
    margin-left: 10px;
  }

  .story_data {
    padding-left: unset;
    padding-right: 16px;
  }
}

@media (max-width: $laptop-sm) {
  .single_story_wrap {
    width: 100%;
  }

  .story_content {
    height: calc(100vh - 140px);
  }

  .video_wrap video {
    max-height: 200px;
    object-fit: cover;
  }

  .story_image {
    height: 125px;
    margin: 16px 0;
  }

  .gal_image {
    height: 125px;
  }

  .detailed_user_wrap {
    margin-top: 65px;
    height: calc(100% - 84px);
  }
}

@media (max-width: $mobile2) {
  .detailes_user_content {
    width: calc(100% + 32px);
    padding: 16px;
    margin-left: -16px;
    min-height: 100vh;
  }

  .story_meta {
    justify-content: space-between;
    width: 100%;
    padding: 16px;
  }

  .author_info {
    border: none;
  }

  .country_info {
    border: none;
    padding: 0;
  }

  .story_title {
    font-size: 22px;
  }

  .story_heading {
    padding: 0;
  }

  .story_text {
    font-size: 14px;
    margin: 16px 0;
  }

  .gallery {
    grid-template-columns: 1fr;
  }

  .detailed_user_info {
    padding: 0 0 16px;
  }

  .detailed_user_avatar {
    width: 68px;
    height: 68px;
  }

  .detailed_user_name {
    font-size: 16px;
    line-height: 24px;
  }

  .detailed_user_role {
    margin-bottom: 4px;
  }

  .detailed_user_location {
    font-size: 14px;

    svg {
      width: 16px;
      margin-right: 5px;
    }
  }

  .close_user_info {
    right: 20px;
    top: 16px;
  }
}
