@import 'src/breakpoints';

.header {
  display: flex;
  align-items: center;
  border-radius: 12px 12px 0 0;
  padding: 16px;
  background-color: white;

  @media (min-width: $tablet) {
    padding: 23px 32px;
    border-radius: 16px 16px 0 0;
  }

  @media (min-width: $laptop-sm) {
    border-bottom: 1px solid var(--catskill);
  }
}

.title {
  display: -webkit-box; /* stylelint-disable-line -- special attribute */
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin-right: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: var(--active-navlink);

  @media (min-width: $tablet) {
    font-size: 22px;
    line-height: 38px;
  }

  @media (min-width: $laptop-sm) {
    display: block;
    line-height: 30px;
  }
}

body[dir='rtl'] {
  .header {
    justify-content: space-between;
  }

  .title {
    margin-right: unset;
    margin-left: 16px;
  }
}
