@import 'src/breakpoints';

.story_action {
  margin: 0 5px;
  height: 40px;
  width: 40px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--catskill);
  border-radius: 50%;
  cursor: pointer;

  svg {
    path {
      fill: var(--slate);
    }
  }

  @media (max-width: $laptop-sm) {
    height: 32px;
    width: 32px;
    border: none;
    border-radius: 8px;
    background: var(--background);

    svg {
      width: 16px;
    }
  }

  &.favorite {
    background: var(--active-navlink);
    border-color: var(--active-navlink);

    svg {
      path {
        fill: var(--white);
      }
    }
  }
}
