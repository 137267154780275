@import 'src/breakpoints';

.back {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 8px;
  width: 32px;
  height: 32px;
  margin-right: 6px;
  background: var(--background);
  border: 1px solid rgb(79 136 179 / 0.04);
  border-radius: 8px;
  cursor: pointer;

  @media (width >= $laptop-sm) {
    width: auto;
    height: auto;
    padding: 0 32px 0 0;
    margin-right: 24px;
    border: 0;
    border-right: 1px solid var(--catskill);
    border-radius: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    background: transparent;
    color: var(--second-text-color);
  }

  .icon {
    flex-shrink: 0;
    margin-right: 9px;
  }
}

body[dir='rtl'] {
  .back {
    flex-direction: row-reverse;
    border-right: unset;
    border-left: 1px solid var(--catskill);
    padding: 0 0 0 32px;
    margin-left: 24px;
    margin-right: unset;
  }
}
