@import 'src/breakpoints';

.feedback_wrap {
  display: flex;
  justify-content: space-between;
}

.feedback_content {
  width: 50%;
  padding: 40px 56px;
}

.feedback_module_title {
  line-height: 36px;
  font-weight: 600;
  font-size: 20px;
  color: var(--active-navlink);
}

.feedback_status {
  margin-bottom: 40px;
  font-size: 54px;
  font-weight: 600;
  line-height: 64px;
  letter-spacing: -0.02em;
  color: var(--pickled);
}

.feedback_rate_label {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: var(--second-text-color);
}

.feedback_rate {
  margin-bottom: 16px;

  svg {
    margin-right: 10px;
    cursor: pointer;
  }
}

.input_form {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid var(--dusty);
  border-radius: 8px;
  outline: none;
}

.feedback_next_module_label {
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: var(--second-text-color);
}

.feedback_next_module_title {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  color: var(--pickled);

  span {
    font-weight: 600;
  }
}

.feedback_actions {
  display: flex;
}

.feedback_illustration {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 50%;
  background: var(--primary-aside-color);
}

.feedback_close {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--navlinkscolor);
    transform: rotate(-45deg);
  }

  &::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--navlinkscolor);
    transform: rotate(45deg);
  }
}

.btn_back_to_courses {
  min-width: 160px;
  margin-right: 16px;
  padding: 10px 20px;
  border: 1px solid var(--active-navlink);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  background: transparent;
  color: var(--active-navlink);
  cursor: pointer;
}

.btn_next_mobule {
  position: relative;
  min-width: 160px;
  margin-right: 16px;
  padding: 10px 30px 10px 20px;
  border: 1px solid var(--active-navlink);
  background-color: var(--active-navlink);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  color: var(--white);
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    right: 15px;
    top: 21px;
    width: 8px;
    height: 8px;
    border-right: 1px solid white;
    border-top: 1px solid white;
    transform: rotate(45deg);
  }
}

@media (max-width: 1000px) {
  .feedback_content {
    width: 100%;
  }

  .feedback_illustration {
    display: none;
  }
}

body[dir='rtl'] {
  .feedback_illustration {
    justify-content: flex-start;
  }

  .feedback_close {
    right: unset;
    left: 40px;
  }
}

@media (max-width: $mobile2) {
  .feedback_actions {
    flex-direction: column;
  }

  .feedback_content {
    padding: 16px;
  }

  .feedback_module_title {
    font-size: 20px;
  }

  .feedback_status {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px;
  }

  .btn_back_to_courses {
    margin: 0;
    margin-bottom: 10px;
  }

  .btn_next_mobule {
    margin: 0;
    margin-bottom: 10px;
  }

  .feedback_close {
    right: 20px;
    top: 20px;
  }
}
