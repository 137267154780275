@import '../../styles/inputs';

.input {
  @include input;

  margin-bottom: 24px;

  @media (min-width: 880px) {
    max-width: 346px;
  }
}

.label {
  @include label;
}

.submit {
  width: 100%;
  height: 48px;

  .inner {
    display: flex;
    align-items: center;
  }

  @media (min-width: 768px) {
    width: auto;
  }
}

.error {
  @include error;

  position: relative;
  bottom: 16px;
}

.icon {
  flex-shrink: 0;
  margin-left: 8px;
}

.required {
  @include required;
}
