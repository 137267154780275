@import 'src/helpers';

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-width: 240px;
  padding: 16px;
  border: 1px solid var(--catskill);
  border-radius: 8px;
  background: white;
  transition: border-color 0.3s;
  cursor: pointer;

  @include hover {
    &:not(.latest_course, .card_empty) {
      border-color: var(--active-navlink);
      box-shadow: 3px 4px 5px rgb(165 122 142 / 0.12);
    }
  }

  .img,
  .placeholder_img {
    width: 100%;
    height: 128px;
    object-fit: cover;
    border-radius: 4px;
  }

  .placeholder_img {
    background: #F4FCFF;
  }

  .title {
    margin: 16px 0 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: var(--slate);
  }

  .text {
    margin-bottom: 16px;
    display: -webkit-box; /* stylelint-disable-line -- special attribute */
    overflow: hidden;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    font-size: 12px;
    line-height: 20px;
    color: var(--second-text-color);
  }

  &.latest_course {
    position: relative;
    padding: 0;
    border: 0;
    margin-bottom: 32px;
    background: transparent;
    z-index: 5;
    cursor: auto;

    .img,
    .placeholder_img {
      height: 167px;
      border-radius: 8px;
    }
  }

  &.module_card {
    .img,
    .placeholder_img {
      height: 120px;
      margin-bottom: 0;
    }

    .title {
      margin-bottom: 18px;
    }
  }

  &.card_empty {
    cursor: auto;
  }
}
