@import 'src/breakpoints';

.story_filter_wrap {
  position: absolute;
  bottom: 16px;
  left: 16px;
  display: flex;
  width: calc(100% - 32px);
  padding: 8px 46px 8px 16px;
  border-radius: 8px;
  /* stylelint-disable-next-line color-function-notation */
  box-shadow: 0 4px 8px rgba(97, 142, 140, 0.12);
  background: var(--white);

  span {
    color: var(--pickled) !important;
  }
}

.story_filter_item {
  margin-right: 16px;
}

.resize {
  position: absolute;
  top: 14px;
  right: 16px;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
}

body[dir='rtl'] {
  .story_filter_wrap {
    padding: 8px 16px 8px 46px;
  }

  .resize {
    right: unset;
    left: 16px;
  }

  .story_filter_item {
    margin-right: unset;
    margin-left: 16px;
  }
}

.filter_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin: 0;
  border: none;
  border-radius: 8px;
  background: var(--white);
  /* stylelint-disable-next-line color-function-notation */
  box-shadow: 0 1px 4px rgba(30, 59, 81, 0.25);
  cursor: pointer;
}

@media (max-width: $mobile2) {
  .story_filter_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .resize {
    position: relative;
    right: unset;
    top: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin: 0;
    border: none;
    border-radius: 8px;
    background: var(--white);
    /* stylelint-disable-next-line color-function-notation */
    box-shadow: 0 1px 4px rgba(30, 59, 81, 0.25);
  }
}

.opened_filter {
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 9;
  height: auto;
  padding: 10px 5px;
  border-radius: 8px;
  background: var(--white);
  /* stylelint-disable-next-line color-function-notation */
  box-shadow: 0 4px 8px rgba(97, 142, 140, 0.12);

  &.top {
    top: unset;
    bottom: 50px;
  }

  div {
    justify-content: start;
  }
}
