.list {
  margin: 0;
  padding: 0;
  color: #64748B;
  list-style: none;
}

.text {
  font-size: 16px;
  line-height: 30px;
}

.item {
  &::before {
    content: '•';
    margin-right: 8px;
  }

  &.checked {
    color: #94A3B8;

    .text {
      color: #94A3B8;
      text-decoration: line-through;
    }
  }
}
