.story_preview_wrap {
  position: absolute;
  top: 24px;
  left: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 454px;
  height: 100%;
  max-width: 356px;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  /* stylelint-disable-next-line color-function-notation */
  box-shadow: 0 4px 8px rgba(97, 142, 140, 0.12);
  background: var(--white);

  .title {
    width: 100%;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
  }

  .content {
    height: 100%;
    padding: 12px;
    border: 1px solid var(--catskill);
    border-radius: 8px;
    background: #FFF;
  }

  .info {
    cursor: pointer;
  }

  &.default_mobile {
    position: fixed;
    top: unset;
    bottom: 0;
    left: 0;
    max-width: none;
    width: 100%;
    z-index: 10;
    border-radius: 16px 16px 0 0;
    box-shadow: -4px -4px 15px 0 rgb(0 0 0 / 0.1);

    .title {
      position: relative;
      width: 100%;
      padding-top: 16px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: calc(50% - 48px);
        width: 96px;
        height: 4px;
        background: var(--navlinkscolor);
        border-radius: 16px;
      }
    }

    &:global(.animate_enter) {
      opacity: 0;
      bottom: -30px;
    }

    &:global(.animate_enter_active) {
      opacity: 1;
      bottom: 0;
      transition: opacity 0.2s linear, bottom 0.2s linear;
    }

    &:global(.animate_exit) {
      opacity: 1;
      bottom: 0;
    }

    &:global(.animate_exit_active) {
      opacity: 0;
      bottom: -30px;
      transition: opacity 0.1s linear 0.1s, bottom 0.2s linear;
    }
  }

  &.full_map_mobile {
    position: fixed;
    top: unset;
    bottom: 90px;
    left: 11px;
    width: calc(100% - 22px);
    max-width: none;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(97 142 140 / 0.12);
    z-index: 10;

    .title {
      display: flex;
      justify-content: space-between;
    }

    .close {
      position: relative;
      top: 5px;
      margin-left: 8px;
      cursor: pointer;
    }

    &:global(.animate_enter) {
      opacity: 0;
      bottom: 85px;
    }

    &:global(.animate_enter_active) {
      opacity: 1;
      bottom: 90px;
      transition: opacity 0.2s linear, bottom 0.2s linear;
    }

    &:global(.animate_exit) {
      opacity: 1;
      bottom: 90px;
    }

    &:global(.animate_exit_active) {
      opacity: 0;
      bottom: 85px;
      transition: opacity 0.2s linear, bottom 0.2s linear;
    }
  }
}

.story_preview_img {
  position: relative;
  width: 100%;
  margin-bottom: 12px;

  img {
    width: 100%;
    height: 167px;
    object-fit: cover;
    border-radius: 8px;
  }

  button {
    position: absolute;
    right: 14px;
    top: 10px;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .action {
    svg {
      filter: drop-shadow(2px 1px 1px rgb(0 0 0 / 0.1));
    }
  }
}

.story_preview_title {
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  color: var(--pickled);
}

.story_preview_text {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: var(--second-text-color);
  margin-bottom: 8px;
  max-height: 161px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: block;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
}

.list {
  z-index: 1;
  width: calc(100% + 8px);
  max-height: 100%;
  padding-right: 8px;
  overflow-y: auto;
  overflow-x: hidden;

  :global(.simplebar-scrollbar) { /* stylelint-disable-line -- Library class name */
    &::before {
      background: var(--navlinkscolor);
    }
  }

  :global(.simplebar-track.simplebar-vertical) { /* stylelint-disable-line -- Library class name */
    width: 8px;
    right: -2px;
  }

  :global(.simplebar-content-wrapper) { /* stylelint-disable-line -- Library class name */
    scroll-behavior: smooth;
  }
}
