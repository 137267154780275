@import 'src/breakpoints';

.modal {
  width: 100%;
  height: 100%;
  background: rgb(51 65 85 / 0.6);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: all 250ms linear;
  z-index: 60;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .modal_content {
    background: var(--white);
    border-radius: 12px;
    transform: scale(0.6);
    max-width: 1064px;
    width: calc(100% - 32px);
    transition: all 250ms linear;
    position: relative;
    overflow: hidden;

    &.story {
      @media (max-width: $laptop-sm) {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
    }

    &.active {
      transform: scale(1);

      .close_modal {
        display: none;
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
      }
    }

    &.xl {
      max-width: 1180px;
    }

    &.xxl {
      max-width: 100%;
      width: 99%;
      height: 95%;
    }

    &.auto {
      width: auto;
      max-width: calc(100% - 32px);
    }

    &.md {
      max-width: 510px;
      padding: 80px 56px 24px 32px;

      @media (max-width: $mobile) {
        padding: 60px 24px 24px;
      }

      .close_modal {
        display: block;

        svg {
          width: 32px;
          height: 32px;
        }
      }
    }

    &.sm {
      max-width: 510px;
      padding: 80px 20px 20px;

      @media (max-width: $mobile) {
        padding: 60px 24px 24px;
      }

      .close_modal {
        display: block;

        svg {
          width: 32px;
          height: 32px;
        }
      }
    }

    &.complete_registration {
      max-width: 510px;
      padding: 80px 32px 24px;

      @media (max-width: $mobile) {
        padding: 60px 24px 24px;
      }

      .close_modal {
        display: block;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    &.md_password {
      max-width: 510px;
      padding: 24px 32px;

      .close_modal {
        display: block;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .registration_wrap {
      display: flex;
      flex-wrap: wrap;
      max-width: 1064px;
      width: 100%;
      background: var(--white);

      @media (max-width: $mobile2) {
        flex-direction: column;
        align-items: center;
      }

      .block_make_choice {
        width: 50%;
        padding: 100px 68px;

        @media (max-width: $mobile2) {
          width: 100%;
          padding: 18px 68px;
        }

        .white_border {
          background: var(--white);
          border-radius: 12px;
          padding: 12px;

          .user_wrap {
            position: relative;
            overflow: hidden;
            text-decoration: none;
            background: rgb(215 236 250 / 0.38);
            border: 1px solid rgb(135 191 231 / 0.17);
            box-shadow: -1px 9px 14px rgb(0 0 0 / 0.04);
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 138px 0;

            @media (max-width: $mobile2) {
              padding: 47px 0;
            }

            .title {
              text-decoration: none;
              font-weight: 600;
              font-size: 32px;
              line-height: 40px;
              letter-spacing: -0.02em;
              color: var(--slate);
              margin: 0 0 25px;
              z-index: 20;
              position: relative;

              @media (max-width: $mobile2) {
                font-size: 22px;
                line-height: 38px;
              }
            }

            .continue_wrap {
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 20;
              position: relative;

              .continue {
                font-weight: 600;
                font-size: 16px;
                line-height: 30px;
                color: var(--active-navlink);

                @media (max-width: $mobile2) {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 20px;
                }
              }

              .icon {
                transition: all 250ms linear;
                margin: 0 0 0 8px;
              }
            }

            .abstraction_img {
              position: absolute;
              bottom: -10px;
              right: 0;

              @media (max-width: $mobile2) {
                bottom: -55px;
                right: -35px;
              }
            }
          }
        }

        &:hover {
          background: var(--primary-aside-color);

          .white_border {
            .user_wrap {
              border: 1px solid transparent;
              background: var(--primary-aside-color);

              .continue_wrap {
                .icon {
                  transform: translate(10px, 0);
                }
              }
            }
          }
        }
      }
    }

    .forgot_password_wrap {
      .modal_title {
        font-weight: 400;
        font-size: 20px;
        line-height: 36px;
        text-align: center;
        color: var(--pickled);
        margin-bottom: 32px;

        @media (max-width: $mobile) {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 20px;
        }
      }

      .modal_btns {
        display: flex;
        justify-content: space-between;

        a {
          margin: 0 5px;
        }
      }
    }

    .changing_password_wrap {
      .modal_title {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 38px;

        /* identical to box height, or 173% */

        /* Color style/slate/600 */

        color: var(--slate);
        margin: 0 0 32px;
      }

      .changing_password_form {
        .button_wrap {
          display: flex;
          justify-content: space-between;
          margin: 20px 0 0;

          .cancel {
            width: calc(50% - 8px);
            height: 48px;
            cursor: pointer;
            background: var(--white);
            border-radius: 8px;
            padding: 0 16px;
            border: 2px solid var(--active-navlink);
            color: var(--active-navlink);
            font-family: Poppins, sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.03em;
          }

          .save {
            width: calc(50% - 8px);
            height: 48px;
            cursor: pointer;
            background: var(--active-navlink);
            border-radius: 8px;
            padding: 0 16px;
            border: 0;
            color: var(--white);
            font-family: Poppins, sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.03em;
          }
        }
      }
    }

    .access_password {
      .modal_title {
        font-weight: 400;
        font-size: 20px;
        line-height: 36px;
        text-align: center;
        color: var(--pickled);
        margin-bottom: 32px;

        @media (max-width: $mobile) {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 20px;
        }
      }
    }

    .age_validation {
      .modal_title {
        font-weight: 400;
        font-size: 20px;
        line-height: 36px;
        text-align: center;
        color: var(--pickled);
        margin-bottom: 32px;

        @media (max-width: $mobile) {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 20px;
        }
      }
    }

    .add_detect_blocker {
      .modal_title {
        font-weight: 400;
        font-size: 20px;
        line-height: 36px;
        text-align: center;
        color: var(--pickled);
        margin-bottom: 10px;

        @media (max-width: $mobile) {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .modal_subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--pickled);
        margin-bottom: 15px;

        @media (max-width: $mobile) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .explore_paragraph_wrap {
      margin-top: -50px;

      .modal_title {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 38px;
        color: var(--slate);
        margin: 0 0 32px;

        @media (max-width: $mobile) {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .modal_subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: var(--pickled);
        margin-bottom: 16px;

        @media (max-width: $mobile) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    @media (max-width: $mobile2) {
      &.md_password {
        padding: 16px;

        .close_modal {
          top: 10px;
          right: 10px;
        }
      }
    }
  }

  @media (max-width: $laptop-sm) {
    &:not(.auto):global(.simplebar-content) { /* stylelint-disable-line -- Library class name */
      padding: 0 16px 16px !important;
    }
  }
}

@media (max-width: $mobile) {
  .modal {
    .modal_content {
      .changing_password_wrap {
        .modal_title {
          font-size: 20px;
        }
      }
    }
  }
}

body[dir='rtl'] {
  .modal {
    .modal_content {
      .close_modal {
        right: unset;
        left: 10px;
      }

      @media (max-width: $mobile2) {
        &.md_password {
          padding: 16px;
        }
      }
    }
  }
}

.passcheck {
  margin: 8px 0;
}
