@import 'src/helpers';

.lang_switcher {
  @extend %flex-btn;

  position: relative;
  padding: 0;
  color: var(--pickled);
  background: transparent;
  width: 40px;
  height: 40px;
  border: 1px solid var(--catskill);
  border-radius: 80px;
  margin-right: 10px;
}

.square {
  padding: 8px;
  width: 32px;
  height: 32px;
  background: var(--background);
  border: 1px solid rgb(79 136 179 / 0.04);
  border-radius: 8px;
}

.text {
  margin-left: 12px;
  font-size: 16px;
  line-height: 21px;
}
