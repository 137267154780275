@import 'src/breakpoints';

.notifications_modal {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  transition: all 250ms linear;
  z-index: 30;
  max-width: 600px;
  right: 60px;
  top: 60px;
  background: #E6F5F7;
  border: 1px solid #E2E8F0;
  box-shadow: 0 4px 12px rgb(36 57 73 / 0.06);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 16px;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  &.type_1 {
    background: #FDFCEB;
  }

  &.type_2 {
    background: #F2FAFB;
  }

  &.type_3 {
    background: #FFF8F0;
  }

  &.type_4 {
    background: #FFF8F9;
  }

  &.type_5 {
    background: #FCF6FC;
  }

  &.type_6 {
    background: #EFF7F9;
  }

  &.type_7 {
    background: #F8FBFF;
  }

  &.type_8 {
    background: #FFF7F2;
  }

  &.type_9 {
    background: #F4F9F6;
  }

  .notifications_modal_content {
    transform: scale(0.6);
    transition: all 250ms linear;
    position: relative;
    display: flex;
    align-items: center;

    &.active {
      transform: scale(1);
    }

    .notification_icon {
      min-width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.type_1 {
        background: rgba(233 224 53 / 0.1);
      }

      &.type_2 {
        background: rgba(116 197 206 / 0.1);
      }

      &.type_3 {
        background: rgba(247 181 101 / 0.1);
      }

      &.type_4 {
        background: rgba(255 179 187 / 0.1);
      }

      &.type_5 {
        background: rgba(221 157 218 / 0.1);
      }

      &.type_6 {
        background: rgba(95 169 188 / 0.1);
      }

      &.type_7 {
        background: #F1F5F9;
      }

      &.type_8 {
        background: rgba(254 175 119 / 0.1);
      }

      &.type_9 {
        background: rgba(137 189 164 / 0.1);
      }
    }

    .notification_message {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: var(--black);
      margin: 0 24px;

      a,
      span {
        color: #C86E99;
      }
    }

    .close_icon {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 48px;
      height: 48px;
    }
  }
}

@media (max-width: $mobile2) {
  .notifications_modal {
    right: 16px;
    top: 16px;
    padding: 8px;
    width: calc(100% - 32px);

    .notifications_modal_content {
      .notification_message {
        margin: 0 8px;
        -webkit-line-clamp: 2;
        display: -webkit-box; /* stylelint-disable-line -- special attribute */
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .close_icon {
        min-width: 30px;
        height: 30px;
      }
    }
  }
}
