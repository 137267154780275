.content {
  width: calc(100vw - 32px);
  max-width: 560px;
  max-height: calc(100vh - 8px);
  position: relative;
  display: flex;
  background-color: white;

  @media (min-width: 1024px) {
    width: 968px;
    max-width: unset;
    min-height: 560px;
  }
}

.close {
  position: absolute;
  width: 18px;
  top: 20px;
  right: 24px;
  cursor: pointer;
  flex-shrink: 0;
  transition: transform 0.15s ease-in-out;

  @media (min-width: 1024px) {
    width: auto;
    top: 40px;
    right: 40px;
  }

  @media (hover: hover) {
    &:hover {
      transform: scale(0.96);
    }
  }
}

.left {
  width: 100%;
  padding: 32px 24px;
  background: white;

  @media (min-width: 680px) {
    padding: 40px;
  }

  @media (min-width: 1024px) {
    width: auto;
    flex-grow: 1;
    padding: 40px 56px;
  }
}

.right {
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    width: 458px;
    justify-content: flex-end;
    align-items: flex-end;
    background: #D7ECFA;
  }
}

.course_name {
  margin-bottom: 2px;
  max-width: calc(100% - 20px);
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  color: #C86E99;
}

.title {
  margin-bottom: 12px;
  color: var(--pickled);
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: -0.64px;
  word-break: break-word;

  @media (min-width: 1024px) {
    font-size: 45px;
    line-height: 50px;
  }
}

.text {
  margin-bottom: 18px;
  color: var(--second-text-color);
  font-size: 12px;
  line-height: 20px;
}

.info {
  margin-bottom: 18px;
  display: flex;
  padding: 12px;
  gap: 12px;
  border-radius: 8px;
  background: var(--background);
  color: var(--navlinkscolor);
  font-size: 12px;
  line-height: 20px;
}

.info_icon {
  flex-shrink: 0;
}

.submit {
  margin-top: 32px;
}

.scroll {
  max-height: 100%;

  :global(.simplebar-scrollbar) { /* stylelint-disable-line -- Library class name */
    &::before {
      background: #90C5EE;
    }
  }

  :global(.simplebar-track.simplebar-vertical) { /* stylelint-disable-line -- Library class name */
    width: 8px;
    right: -12px;
  }
}
