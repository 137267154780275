.survey_wrap {
  display: flex;
  justify-content: space-between;
}

.survey_content {
  width: 100%;
  padding: 40px 56px;
  height: 100vh;
  overflow: auto;
}

.survey_module_title {
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  color: var(--active-navlink);
}

.survey_status {
  font-weight: 600;
  font-size: 54px;
  line-height: 64px;
  margin-bottom: 40px;
  letter-spacing: -0.02em;
  color: var(--pickled);
}

.survey_rate_label {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: var(--second-text-color);
  margin-bottom: 10px;
}

.survey_rate {
  margin-bottom: 16px;

  svg {
    margin-right: 10px;
    cursor: pointer;
  }
}

.input_form {
  border: 1px solid var(--dusty);
  border-radius: 8px;
  margin-bottom: 40px;
  width: 100%;
  outline: none;
  padding: 10px;
}

.survey_next_module_label {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: var(--second-text-color);
  margin-bottom: 4px;
}

.survey_next_module_title {
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  color: var(--pickled);
  margin-bottom: 16px;

  span {
    font-weight: 600;
  }
}

.survey_actions {
  display: flex;
}

.survey_illustration {
  width: 50%;
  background: var(--primary-aside-color);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.survey_close {
  position: absolute;
  right: 40px;
  top: 40px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--navlinkscolor);
    transform: rotate(-45deg);
  }

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--navlinkscolor);
    transform: rotate(45deg);
  }
}

.btn_back_to_courses {
  color: var(--active-navlink);
  border: 1px solid var(--active-navlink);
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  min-width: 160px;
  margin-right: 16px;
  background: transparent;
  cursor: pointer;
}

.btn_next_mobule {
  color: var(--white);
  min-width: 160px;
  border: 1px solid var(--active-navlink);
  background-color: var(--active-navlink);
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    right: 15px;
    top: 21px;
    width: 8px;
    height: 8px;
    border-right: 1px solid white;
    border-top: 1px solid white;
    transform: rotate(45deg);
  }
}

@media (max-width: 1000px) {
  .survey_content {
    width: 100%;
  }

  .survey_illustration {
    display: none;
  }
}

@media (max-width: 600px) {
  .survey_actions {
    flex-direction: column;
  }

  .survey_content {
    padding: 40px 36px;
  }
}
