@import 'src/breakpoints';

.random_story_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 40px;
  margin: 0 12px;
  padding: 0 18px;
  border: 1px solid var(--catskill);
  border-radius: 80px;
  background: transparent;
  color: var(--slate);
  cursor: pointer;

  svg {
    margin: 0 11px;

    path {
      fill: var(--slate);
    }
  }

  &.mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin: 0 8px 0 4px;
    padding: 0;
    border-radius: 12px;
    background: var(--background);
    border: 0;

    span {
      display: none;
    }
  }

  &.small {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    margin: 0 4px;
    border: 1px solid rgb(79 136 179 / 0.04);

    svg {
      margin: 0;
    }
  }

  &.modal_icon {
    @media (max-width: $laptop-sm) {
      width: 32px;
      height: 32px;
      background: var(--background);

      svg {
        margin: 0 8px;
      }
    }
  }
}
