@import 'src/breakpoints';

.section_title {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  margin: 0 0 8px;
  color: var(--slate);
}

.container_slider {
  display: flex;
  align-items: center;
  max-width: 360px;
  position: relative;
  justify-content: space-between;
  margin: 0 0 24px;
}

.btn_slide {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 40px;
  height: 40px;
  border: 1px solid var(--catskill);
  border-radius: 50%;
  z-index: 10;

  svg {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
}

.slide {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  opacity: 1;
}

body[dir='rtl'] {
  .container_slider {
    flex-direction: row-reverse;
  }
}

@media (max-width: $mobile2) {
  .container_slider {
    max-width: 100%;
  }
}
