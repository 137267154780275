@import 'variables';
@import 'breakpoints';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: 100%;
  padding: 0;
}

body {
  font-family: Poppins, serif;

  @media (width < $laptop-sm) {
    background-color: var(--background);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

input,
button,
select {
  font: inherit;
  outline: none;
}

a {
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
}

.simplebar-placeholder { /* stylelint-disable-line -- library glitch fix */
  width: auto !important;
}

/* stylelint-disable */
.btn-certificate,
.btn-certificate * {
  cursor: pointer;
  text-decoration: underline;
  color: var(--navlinkscolor);
  font-weight: 600;
}
/* stylelint-enable */
