.story_card {
  width: 100%;
  height: 100%;
  padding: 12px;
  border: 1px solid var(--catskill);
  border-radius: 8px;
  background: #FFF;

  .story_preview_img {
    position: relative;
    width: 100%;
    margin-bottom: 12px;

    img {
      width: 100%;
      height: 167px;
      object-fit: cover;
      border-radius: 8px;
    }

    button {
      position: absolute;
      right: 14px;
      top: 10px;
      padding: 0;
      border: none;
      background: transparent;
      cursor: pointer;
    }

    .action {
      svg {
        filter: drop-shadow(2px 1px 1px rgb(0 0 0 / 0.1));
      }
    }
  }

  .placeholder_img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }

  .story_preview_title {
    display: -webkit-box; /* stylelint-disable-line -- special attribute */
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    color: var(--pickled);
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }

  .story_preview_text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: var(--second-text-color);
    margin-bottom: 8px;
    max-height: 161px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: block;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
  }

  .title {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
  }

  .author {
    font-size: 12px;
    color: var(--slate);
    line-height: 20px;
  }

  .info {
    cursor: pointer;
  }

  &.list {
    display: flex;
    height: 138px;
    gap: 0 12px;

    .story_preview_img {
      flex-shrink: 0;
      width: 112px;
      margin: 0;

      img {
        height: 114px;
      }
    }

    .story_preview_title {
      margin-bottom: 4px;
      font-size: 16px;
      line-height: 30px;
    }

    .story_preview_text {
      display: -webkit-box; /* stylelint-disable-line -- special attribute */
      overflow: hidden;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
    }

    .info {
      flex-grow: 1;
    }
  }
}
