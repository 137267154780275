@import 'src/helpers';
@import 'src/breakpoints';

.menu {
  position: absolute;
  top: 39px;
  right: -20px;
  min-width: 126px;
  padding: 8px 0 0;
  margin: 0;
  list-style: none;
  z-index: 20;
  height: 250px;
  overflow: auto;

  @media (width < $laptop-sm) {
    top: 27px;
    right: 0;
    min-width: 190px;
  }

  @media (min-width: $laptop-sm) {
    min-width: 0;
  }

  &:global(.animate_enter) {
    opacity: 0;
  }

  &:global(.animate_enter_done) {
    opacity: 1;
    transition: opacity 0.25s;
  }

  &:global(.animate_exit) {
    opacity: 1;
  }

  &:global(.animate_exit_done) {
    opacity: 0;
    transition: opacity 0.25s;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: var(--white);
  border: 1px solid #E2E8F0;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #475569;
  transition: color 0.25s;

  &:first-child {
    border-radius: 16px 16px 0 0;
    border-bottom: 0;

    @media (width < $laptop-sm) {
      border-radius: 8px 8px 0 0;
    }
  }

  & + & {
    border-bottom: 0;

    @media (width < $laptop-sm) {
      border-bottom: 0;
      border-top: 0;
    }
  }

  &:last-child {
    border-bottom: 1px solid #E2E8F0;
    border-radius: 0 0 16px 16px;

    @media (width < $laptop-sm) {
      border-top: 0;
      border-radius: 0 0 8px 8px;
    }
  }

  @include hover {
    color: var(--mirage);
  }
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  margin: 0 8px 0 0;
  border: 1px solid var(--navlinkscolor);
  border-radius: 4px;
  transition: border-color 0.2s;

  @media (min-width: $laptop-sm) {
    display: none;
  }

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    background: transparent;
    border-radius: 2px;
    transition: background-color 0.2s;
  }
}

.is_active {
  color: var(--active-navlink);

  .checkbox {
    border-color: var(--active-navlink);

    &::before {
      background-color: var(--active-navlink);
    }
  }
}
