@import 'src/breakpoints';

:global(.personal_avatar) { /* stylelint-disable-line -- Library class name */
  width: 180px;
  height: 180px;
  border: 8px solid var(--background);
  border-radius: 50%;
  overflow: hidden;
  margin: 0 0 16px;

  svg {
    width: 170px;
    height: 170px;
  }

  @media (max-width: $mobile2) {
    margin: 0 auto 20px;
  }
}

.modal_logo {
  position: absolute;
  left: 32px;
  top: 32px;
  z-index: 50;
  background-color: rgb(255 255 255 / 0.3);
  border-radius: 100px;
}

.customize_platform_wrapper {
  height: 100vh;
  background-color: var(--white);
  position: fixed;
  opacity: 0;
  pointer-events: none;
  transition: all 250ms linear;
  z-index: 30;
  width: 100%;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .left_block {
    width: 50%;
    height: 100%;
    overflow: auto;

    @media (max-width: $mobile2) {
      padding: 20px 0;
    }

    :global(.simplebar-scrollbar) { /* stylelint-disable-line -- Library class name */
      &::before {
        background: var(--navy-bg);
        width: 4px;
        left: 4px;
      }
    }

    .form_wrap {
      margin: 0 auto;
      max-width: 464px;
      padding: 120px 20px;
    }

    .account_header {
      display: flex;
      justify-content: space-between;
      padding: 0 32px;
      align-items: center;
      height: 88px;
      border-bottom: 1px solid var(--catskill);

      .account_title {
        font-family: Poppins, sans-serif;
        font-weight: 600;
        font-size: 22px;
        line-height: 38px;
        color: var(--active-navlink);
      }
    }

    .info {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      background: rgba(215 236 250 / 0.5);
      border-radius: 12px;
      padding: 8px;
      margin: 0 auto 40px;
    }

    .personal_avatar {
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      color: var(--slate);
      margin: 0 0 16px;
    }

    .disable_avatar_settings {
      &.hide {
        opacity: 0.2;
        position: relative;

        &::before {
          z-index: 11;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .preview_user_avatar {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .user_avatar_background {
        width: 384px;
        height: 384px;
        border: 8px solid #E8F6FF;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        svg {
          width: 324px;
          height: 350px;
          bottom: -5px;
          position: relative;

          @media (max-width: $mobile2) {
            width: 155px;
            height: 160px;
          }
        }
      }

      .default_avatar {
        width: 384px;
        height: 384px;
        border: 8px solid #E8F6FF;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        svg {
          width: 384px;
          height: 384px;
          bottom: -5px;
          position: relative;

          @media (max-width: $mobile2) {
            width: 155px;
            height: 160px;
          }
        }
      }
    }

    .site_setting {
      border-bottom: 1px solid var(--catskill);
    }

    .section_title {
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      margin: 0 0 8px;
      color: var(--slate);
    }

    .sidebar_pattern_img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }

    .checked_icon {
      display: none;
    }

    .background_sidebar_pattern {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 16px;

      .pattern_item {
        height: 70px;
        width: calc(16.66% - 16px);
        position: relative;
        margin: 0 0 16px;
        border-radius: 16px;

        &.active {
          padding: 6px;
          border-radius: 16px;
          border: 2px solid var(--active-navlink);

          @media (max-width: $mobile2) {
            border-radius: 50%;
          }

          .sidebar_pattern_img {
            border: 6px solid var(--white);

            @media (max-width: $mobile2) {
              border: 4px solid var(--white);
            }
          }

          .color_box {
            border-radius: 16px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .sidebar_pattern_item {
        height: 70px;
        width: calc(16.66% - 16px);
        cursor: pointer;

        input {
          display: none;
        }

        input:checked + div {
          .checked_icon {
            display: flex;
            z-index: 10;
            position: relative;

            @media (max-width: $mobile2) {
              display: none;
            }
          }
        }
      }
    }

    .background_color_pattern {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 16px;

      .background_color_pattern_item {
        height: 70px;
        width: calc(16.66% - 16px);
        cursor: pointer;
        position: relative;
        margin: 0 0 16px;
        border-radius: 16px;

        .single_color_pattern {
          border-radius: 16px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.active {
          background: var(--white);
          padding: 6px;
          border: 2px solid var(--active-navlink);

          .sidebar_pattern_img {
            border: 6px solid var(--white);
          }

          @media (max-width: $mobile2) {
            padding: 4px;
          }
        }

        input {
          display: none;
        }

        input:checked + div {
          .checked_icon {
            display: flex;
            z-index: 10;
            position: relative;

            @media (max-width: $mobile2) {
              display: none;
            }
          }
        }
      }
    }

    .avatar_wrap {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid var(--catskill);
      margin: 0 0 32px;
    }

    .button_wrap {
      display: flex;
      margin: 32px 0 0;

      .cancel {
        height: 48px;
        margin: 0 8px 0 0;
        cursor: pointer;
        background: var(--white);
        border-radius: 8px;
        padding: 0 16px;
        border: 2px solid var(--active-navlink);
        color: var(--active-navlink);
        font-family: Poppins, sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.03em;
        transition: all 0.5s ease-out;

        &:hover {
          filter: drop-shadow(0 2px 4px rgb(111 57 82 / 0.21));
        }

        @media (max-width: $mobile2) {
          padding: 0 6px;
          font-size: 14px;
        }
      }

      .save {
        height: 48px;
        cursor: pointer;
        background: var(--active-navlink);
        border-radius: 8px;
        padding: 0 16px;
        border: 0;
        color: var(--white);
        font-family: Poppins, sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.03em;
        transition: all 0.5s ease-out;

        &:hover {
          box-shadow: 0 2px 4px rgb(111 57 82 / 0.21);
        }

        @media (max-width: $mobile2) {
          padding: 0 6px;
          font-size: 14px;
        }
      }
    }

    .select_style_box_wrap {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;
      margin: 0 0 32px;

      .select_style_color_item {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 30px;
        color: var(--navlinkscolor);
        margin: 0 24px 0 0;
        cursor: pointer;

        &.active {
          color: var(--active-navlink);
          border-bottom: 2px solid var(--active-navlink);
        }

        .select_style_input {
          display: none;
        }
      }
    }

    .body_color_box_wrap {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;

      .body_color_box_item {
        height: 40px;
        width: 40px;
        cursor: pointer;
        position: relative;
        margin: 0 24px 24px 0;

        &.active {
          border: 2px solid var(--active-navlink);
          border-radius: 50%;
          padding: 4px;
        }

        input {
          display: none;
        }

        input:checked + div {
          position: relative;
        }
      }
    }

    .hair_color_box_wrap {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;
      margin: 0 0 16px;

      .hair_color_box_item {
        height: 40px;
        width: 40px;
        cursor: pointer;
        position: relative;
        margin: 0 24px 24px 0;

        &.active {
          border: 2px solid #C86E99;
          border-radius: 50%;
          padding: 4px;
        }

        input {
          display: none;
        }

        input:checked + div {
          display: flex;
        }

        &.hair_color_color_piker {
          border: 1px solid #E2E8F0;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          &.active {
            border: 2px solid #C86E99;
          }
        }
      }
    }

    .avatar_background_color_box_wrap {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;
      margin: 0 0 8px;

      .avatar_background_color_box_item {
        height: 40px;
        width: 40px;
        cursor: pointer;
        position: relative;
        margin: 0 24px 24px 0;

        &.active {
          border: 2px solid #C86E99;
          border-radius: 50%;
          padding: 4px;
        }

        input {
          display: none;
        }

        input:checked + div {
          display: flex;
        }
      }
    }

    .body_color_box {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .hair_color_box {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .avatar_background_color_box {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .block_account {
      position: fixed;
      padding: 0 20px;
      height: 63px;
      right: 63px;
      top: 60px;
      box-shadow: 3px 4px 5px rgb(36 57 73 / 0.06);
      border-radius: 8px;
    }

    .saving_data {
      font-size: 18px;
      color: var(--green);
      display: flex;
      align-items: center;
      margin: 0 0 0 30px;
    }

    .success_save {
      background: var(--nitification-background);
      border: 1px solid var(--catskill);
      font-size: 18px;
      color: var(--green);
      display: flex;
      align-items: center;
    }

    .error_save {
      background: var(--nitification-background2);
      border: 1px solid var(--nitification-border2);
      font-size: 18px;
      color: var(--errors);
      display: flex;
      align-items: center;
    }

    @media (max-width: $laptop) {
      .avatar_wrap {
        flex-direction: column-reverse;
      }

      .preview_user_avatar {
        width: 100%;

        .user_avatar_background {
          width: 250px;
          height: 250px;

          svg {
            width: 250px;
            height: 250px;
          }
        }
      }

      .avatar_settings {
        width: 100%;
      }
    }

    @media (max-width: $mobile2) {
      .account_header {
        display: none;
      }

      .avatar_wrap {
        flex-direction: column-reverse;
        margin: 0 0 64px;

        .avatar_settings {
          width: 100%;
        }

        .preview_user_avatar {
          width: 100%;

          .user_avatar_background {
            width: 180px;
            height: 180px;

            svg {
              width: 155px;
              height: 160px;
            }
          }
        }
      }

      .body_color_box_wrap {
        justify-content: space-between;

        .body_color_box_item {
          margin: 0 0 24px;
        }
      }

      .hair_color_box_wrap {
        justify-content: space-between;

        .hair_color_box_item {
          margin: 0 0 24px;
        }
      }

      .avatar_background_color_box_wrap {
        justify-content: space-between;
        margin: 0 0 40px;

        .avatar_background_color_box_item {
          margin: 0 0 24px;
        }
      }

      .background_color_pattern {
        .background_color_pattern_item {
          height: 40px;
          padding: 0;
          border-radius: 50%;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          .single_color_pattern {
            border-radius: 50%;
            width: 40px;
            height: 40px;
          }

          &.active {
            .single_color_pattern {
              border-radius: 50%;
              width: 28px;
              height: 28px;
            }
          }
        }
      }

      .background_sidebar_pattern {
        .pattern_item {
          height: 40px;
          width: 40px;
          border-radius: 50%;

          .sidebar_pattern_img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 0;
          }

          &.active {
            .sidebar_pattern_img {
              width: 36px;
              height: 36px;
            }
          }
        }
      }

      .button_wrap {
        .cancel {
          width: 50%;
        }

        .save {
          width: 50%;
        }
      }
    }

    :global(.pattern_1) { /* stylelint-disable-line -- Library class name */
      background: var(--background-color-pattern-1);
    }

    :global(.pattern_2) { /* stylelint-disable-line -- Library class name */
      background: var(--background-color-pattern-2);
    }

    :global(.pattern_3) { /* stylelint-disable-line -- Library class name */
      background: var(--background-color-pattern-3);
    }

    :global(.pattern_4) { /* stylelint-disable-line -- Library class name */
      background: var(--background-color-pattern-4);
    }

    :global(.pattern_5) { /* stylelint-disable-line -- Library class name */
      background: var(--background-color-pattern-5);
    }

    :global(.pattern_6) { /* stylelint-disable-line -- Library class name */
      background: var(--background-color-pattern-6);
    }

    :global(.body_color_pattern_1) { /* stylelint-disable-line -- Library class name */
      background: var(--body-color-pattern-1);
    }

    :global(.body_color_pattern_2) { /* stylelint-disable-line -- Library class name */
      background: var(--body-color-pattern-2);
    }

    :global(.body_color_pattern_3) { /* stylelint-disable-line -- Library class name */
      background: var(--body-color-pattern-3);
    }

    :global(.body_color_pattern_4) { /* stylelint-disable-line -- Library class name */
      background: var(--body-color-pattern-4);
    }

    :global(.body_color_pattern_5) { /* stylelint-disable-line -- Library class name */
      background: var(--body-color-pattern-5);
    }

    :global(.body_color_pattern_6) { /* stylelint-disable-line -- Library class name */
      background: var(--body-color-pattern-6);
    }

    :global(.hair_color_pattern_1) { /* stylelint-disable-line -- Library class name */
      background: var(--hair-color-pattern-1);
    }

    :global(.hair_color_pattern_2) { /* stylelint-disable-line -- Library class name */
      background: var(--hair-color-pattern-2);
    }

    :global(.hair_color_pattern_3) { /* stylelint-disable-line -- Library class name */
      background: var(--hair-color-pattern-3);
    }

    :global(.hair_color_pattern_4) { /* stylelint-disable-line -- Library class name */
      background: var(--hair-color-pattern-4);
    }

    :global(.hair_color_pattern_5) { /* stylelint-disable-line -- Library class name */
      background: var(--hair-color-pattern-5);
    }

    :global(.avatar_background_pattern_1) { /* stylelint-disable-line -- Library class name */
      background: var(--avatar-background-pattern-1);
    }

    :global(.avatar_background_pattern_2) { /* stylelint-disable-line -- Library class name */
      background: var(--avatar-background-pattern-2);
    }

    :global(.avatar_background_pattern_3) { /* stylelint-disable-line -- Library class name */
      background: var(--avatar-background-pattern-3);
    }

    :global(.avatar_background_pattern_4) { /* stylelint-disable-line -- Library class name */
      background: var(--avatar-background-pattern-4);
    }

    :global(.avatar_background_pattern_5) { /* stylelint-disable-line -- Library class name */
      background: var(--avatar-background-pattern-5);
    }

    :global(.simplebar-content-wrapper) { /* stylelint-disable-line -- Library class name */
      scroll-behavior: smooth;
    }
  }

  .right_block {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    background: var(--primary-aside-color);
    height: 100%;

    .icon_abstraction {
      display: none;

      @media (max-width: $mobile2) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .preview_user_site {
      display: flex;

      svg { /* stylelint-disable-line -- special attribute */
        width: 100%;
        height: calc(100vh - 64px);

        @media (max-width: $mobile2) {
          height: 150px;
          right: -16px;
          position: relative;
        }
      }
    }
  }
}

@media (max-width: $mobile2) {
  .modal_logo {
    left: 16px;
    top: 15px;
  }

  .customize_platform_wrapper {
    flex-direction: column-reverse;
    height: auto;
    padding: 0 16px;

    .left_block {
      width: 100%;

      .select_style_box_wrap {
        justify-content: center;
      }
    }

    .right_block {
      width: 100%;
      background: var(--white);
      padding: 30px 0 20px;
    }

    &.active {
      position: absolute;
    }
  }
}
