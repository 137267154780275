@import 'src/breakpoints';

.recent_stories {
  position: relative;
  padding: 40px 32px;
}

.recent_stories_title {
  font-weight: 600;
  font-size: 22px;
  line-height: 38px;
  color: var(--pickled);
}

.recent_stories_wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.recent_stories_item {
  width: calc(50% - 12px);
  margin-top: 24px;
  border: 1px solid var(--catskill);
  border-radius: 8px;
  padding: 12px 16px;

  &:nth-child(odd) {
    margin-right: 24px;
  }

  .item_course_title {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: var(--mirage);
  }

  .item_module_title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--active-navlink);
    margin-bottom: 12px;
  }

  .preview_img {
    position: relative;

    .action {
      position: absolute;
      right: 10px;
      top: 10px;
      border: none;
      background: transparent;
      padding: 0;
      margin: 0;
      cursor: pointer;

      svg {
        filter: drop-shadow(2px 1px 1px rgb(0 0 0 / 0.1));
      }
    }
  }

  .item_preview_img,
  .placeholder_img {
    height: 167px;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 12px;
  }

  .placeholder_img {
    background: #F4FCFF;
  }

  .item_author {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: var(--slate);
  }

  .item_title {
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    color: var(--pickled);
    margin-bottom: 8px;
    cursor: pointer;
  }

  .item_preview_text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: var(--second-text-color);
    overflow: hidden;
    text-overflow: ellipsis;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

body[dir='rtl'] {
  .recent_stories_item {
    &:nth-child(odd) {
      margin-left: 24px;
      margin-right: unset;
    }
  }
}

@media (max-width: $mobile2) {
  .recent_stories {
    padding: 24px 0;
  }

  .recent_stories_item {
    width: 100%;
    margin-top: 16px;

    &:nth-child(odd) {
      margin-right: 0;
    }
  }
}

.search_filter_container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.search_input {
  flex: 1;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid var(--catskill);
  border-radius: 6px;
  outline: none;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: var(--active-navlink);
  }
}

.country_dropdown {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid var(--catskill);
  border-radius: 6px;
  background-color: white;
  cursor: pointer;
}
