@import 'src/breakpoints';
@import 'src/helpers';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  border: 0;
  padding: 9px 16px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  transition: all 250ms linear;
  min-width: 195px;

  @media (max-width: $mobile-m) {
    min-width: unset;
    width: 100%;
  }

  &.button_fill {
    background: #C86E99;
    color: #FFF;

    @include hover {
      box-shadow: 0 2px 4px rgb(111 57 82 / 0.21);
    }

    &:focus {
      background: linear-gradient(180deg, #923260 0%, #C94584 0.01%, #C86E99 95.31%);
      box-shadow: 0 2px 4px rgb(111 57 82 / 0.21);
    }

    &.full_width {
      width: 100%;
    }
  }

  &.link {
    display: inline-flex;
  }

  &.button_border {
    border: 2px solid #C86E99;
    color: #C86E99;
    background: #FFF;
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;

    @include hover {
      box-shadow: none;
    }
  }
}
