.radio_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  input {
    display: none;
  }

  .label {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: var(--pickled);
  }

  .radio_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    border: 1px solid var(--navlinkscolor);
    margin: 0 7px;
    border-radius: 4px;

    &::before {
      content: '';
      display: block;
      opacity: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background: var(--active-navlink);
      border: 1px solid white;
    }
  }

  &.active {
    .radio_btn {
      border: 1px solid var(--active-navlink);

      &::before {
        opacity: 1;
      }
    }
  }
}
