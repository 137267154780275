.feedback_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 20px;
}

.feedback_content p {
  margin-bottom: 20px;
}
