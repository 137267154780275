.story_map_wrap {
  position: relative;
  width: 100%;
  height: 100% !important;

  .map {
    width: 100% !important;
    height: calc(100% - 90px);
  }
}

.map_view {
  width: 100%;
  margin: 12px 0;
  padding: 10px 53px;
  border: none;
  background: var(--active-navlink);
  color: var(--white);
  cursor: pointer;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin: 0 0 0 auto;
}

.bottom_action {
  position: absolute;
  width: 100%;
}
