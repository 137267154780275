.logo {
  max-width: 100%;
  flex-shrink: 0;
}

.default {
  width: 102px;
  height: 90px;
}

.small {
  width: 80px;
  height: 70px;
}
