.level {
  width: 25%;
  height: 4px;
  background-color: #E5E7EB;
}

.levels {
  display: flex;
  gap: 4px;

  &.level_1 {
    .level:nth-child(1) {
      background-color: #FBBF24;
    }
  }

  &.level_2 {
    .level:nth-child(-n+2) {
      background-color: #FCD34D;
    }
  }

  &.level_3 {
    .level:nth-child(-n+3) {
      background-color: #BDC54F;
    }
  }

  &.level_4 {
    .level:nth-child(-n+4) {
      background-color: #65A30D;
    }
  }
}
